import React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const FaqContainer = styled.div`
    border: 1px solid #e0e0e0;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 15px;
`;

const StyledTextarea = styled.textarea`
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    width: 100%;
    resize: vertical; /* This allows vertical resizing */
`;


const RemoveButton = styled.button`
    margin-top: 10px;
    color: red;
    cursor: pointer;
`;

const AddButton = styled.button`
    margin-top: 10px;
    color: blue;
    cursor: pointer;
    max-width: 250px;
`;

const Faqs = ({ control, fieldName, defaultValue }) => {
    const [faqData, setFaqData] = useState([]);

    useEffect(() => {
        console.log("faqData top:", faqData)
        console.log("Default Value :", defaultValue)

        if (defaultValue && defaultValue.length > 0) {
            setFaqData(defaultValue);
        } 
    }, [defaultValue]);
    
    
    const addNewFaq = () => {
        const newFaq = { question: "", answer: "" };
        setFaqData([...faqData, newFaq]);
    };

    const removeFaq = (index) => {
        const updatedFaqData = [...faqData];
        updatedFaqData.splice(index, 1);
        setFaqData(updatedFaqData);
    };

    return (
        <Container>
            {faqData.map((faq, i) => (
                <FaqContainer key={i}>
                    <InputContainer>
                        <Controller
                            control={control}
                            name={`${fieldName}[${i}].question`}
                            defaultValue={faq.question || ""}
                            render={({ field }) => (
                                <>
                                    <label htmlFor={`faq${i}_question`}>Question {i + 1}</label>
                                    <StyledTextarea {...field} type="text" id={`faq${i}_question`} />
                                </>
                            )}
                        />
                    </InputContainer>
                    <InputContainer>
                        <Controller
                            control={control}
                            name={`${fieldName}[${i}].answer`}
                            defaultValue={faq.answer || ""}
                            render={({ field }) => (
                                <>
                                    <label htmlFor={`faq${i}_answer`}>Answer</label>
                                    <StyledTextarea {...field} type="text" id={`faq${i}_answer`} />
                                </>
                            )}
                        />
                    </InputContainer>
                    <RemoveButton type="button" onClick={() => removeFaq(i)}>
                        Remove FAQ
                    </RemoveButton>
                </FaqContainer>
            ))}
            <AddButton type="button" onClick={addNewFaq}>
                Add New FAQ
            </AddButton>
        </Container>
    );
};

export default Faqs;
